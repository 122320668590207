import React, { useState } from 'react';
import jsonp from 'jsonp';

import './styles.scss'

function NewsletterSignupForm({ button, link, submitButtonLabel = "Sign up" }) {

    const [newsletterSignupState, setNewsletterSignup] = useState('open')
    const [newsletterSignupEmail, setNewsletterSignupEmail] = useState('');

    function handleEmailInput(e) {
        e.preventDefault();
        setNewsletterSignupEmail(e.target.value);
    }

    function submitSignUp() {
        setNewsletterSignup('pending');

        jsonp(`https://voalabs.us19.list-manage.com/subscribe/post-json?u=3bfc9faffec83300d9685a6a8&amp;id=77e58ffb9c&EMAIL=${newsletterSignupEmail}`, { param: 'c' }, (err, data) => {
            if (data.result === "success") {
                //TODO: IF success show success msg otherwise error
                setNewsletterSignup('success');
            } else {
                setNewsletterSignup('error');
            }
        });
    }

    return (
        <div class="remove-margin w-form">
            {newsletterSignupState !== 'success' &&
                <div className="cta-form">
                    <div className="BlogPost__newsletterSignupInputWrapper">
                        <input
                            type="email"
                            className="BlogPost__newsletterSignupInput"
                            maxlength="128"
                            name="email"
                            data-name="email"
                            placeholder="Your Email"
                            id="email"
                            required=""
                            onChange={handleEmailInput}
                        />
                    </div>
                    <div class="cta-form-button">
                        <button
                            className={`button is-big is-footer BlogPost__newsletterSignupSubmit ${button === 'outline' ? "is-outline" : "is-red"}`}
                            onClick={submitSignUp}
                            onMouseDown={e => e.preventDefault()}
                        >
                            <div class="button-text">
                                {submitButtonLabel}
                            </div>
                        </button>
                    </div>
                </div>

            }
            {newsletterSignupState === 'success' &&
                <div class="BlogPost__newsletterSignup--success">

                    {link === undefined ?
                        <div>Thank you for subscribing!</div>
                        :
                        <a href={link} target="_blank" >Here's the link to your document</a>
                    }
                </div>
            }
            {newsletterSignupState === 'error' &&
                <div class="BlogPost__newsletterSignup--fail">
                    <div>Oops! Something went wrong. Try again please.</div>
                </div>
            }
        </div>
    )
}

export default NewsletterSignupForm;